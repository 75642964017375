import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import AdminArticleCreate from "../AdminArticlePage/AdminArticleCreate";
import ArticleList from "../AdminArticlePage/AdminArticleList";
import AdminArticleView from "../AdminArticlePage/AdminArticleView";
import UserManagement from "../AdminUserManagement/UserManagement";
import DictionaryMainPage from "../DictionaryMainPage/DictionaryMainPage";
import SongAdmin from "../SongAdmin/SongAdmin";
import SongsList from "../SongAdmin/SongsList";
import SongView from "../SongAdmin/SongView";
import AdminTorreyList from "../AdminTorrey/AdminTorreyList";
import AdminTorreyCreate from "../AdminTorrey/AdminTorreyCreate";
import AdminTorreyView from "../AdminTorrey/AdminTorreyView";
import AdminMapList from "../AdminMapPage/AdminMapList";
import AdminMapCreate from "../AdminMapPage/AdminMapCreate";
import AdminMapView from "../AdminMapPage/AdminMapView";
import AdminChapterTitle from "../AdminChapterTitle/AdminChapterTitleList";
import AdminChapterCreate from "../AdminChapterTitle/AdminChapterCreate";
import AdminChapterTitleView from "../AdminChapterTitle/AdminChapterTitleView";

export default function AdminDashboardPage({ setCurrentPage }) {
  let match = useRouteMatch();
  return (
    <div className="admin-dashboard-page">
      <div className="admin-sidebar">
        <NavLink className="item" to={`${match.url}/articles`}>
          Articles
        </NavLink>
        <NavLink className="item" to={`${match.url}/verses`}>
          Chapter Title
        </NavLink>
        <NavLink className="item" to={`${match.url}/dictionary`}>
          Dictionary
        </NavLink>
        <NavLink className="item" to={`${match.url}/maps`}>
          Maps
        </NavLink>
        <NavLink className="item" to={`${match.url}/songs`}>
          Songs
        </NavLink>
        <NavLink className="item" to={`${match.url}/torreys`}>
          Torrey's
        </NavLink>
        <NavLink className="item" to={`${match.url}/user-management`}>
          Users
        </NavLink>
      </div>
      <div className="main-section">
        <Switch>
          <Route path={`${match.path}/articles`}>
            <ArticleList setCurrentPage={setCurrentPage} />
          </Route>
          <Route path={`${match.path}/create-article`}>
            <AdminArticleCreate />
          </Route>
          <Route path={`${match.path}/view-article/:id`}>
            <AdminArticleView />
          </Route>
          <Route path={`${match.path}/edit-article/:id`}>
            <AdminArticleCreate />
          </Route>
          <Route path={`${match.path}/verses`}>
            <AdminChapterTitle />
          </Route>
          <Route path={`${match.path}/verses-create`}>
            <AdminChapterCreate />
          </Route>
          <Route
            path={`${match.path}/verses-edit/book/:bookNumber/chapter/:chapter/version/:version`}
          >
            <AdminChapterCreate />
          </Route>
          <Route
            path={`${match.path}/verses-view/book/:bookNumber/chapter/:chapter/version/:version`}
          >
            <AdminChapterTitleView />
          </Route>
          <Route path={`${match.path}/dictionary`}>
            <DictionaryMainPage />
          </Route>
          <Route path={`${match.path}/songs`}>
            <SongsList />
          </Route>
          <Route path={`${match.path}/create-song`}>
            <SongAdmin />
          </Route>
          <Route path={`${match.path}/edit-song/:id`}>
            <SongAdmin />
          </Route>
          <Route path={`${match.path}/view-song/:id`}>
            <SongView />
          </Route>
          <Route path={`${match.path}/torreys`}>
            <AdminTorreyList />
          </Route>
          <Route path={`${match.path}/create-torrey`}>
            <AdminTorreyCreate />
          </Route>
          <Route path={`${match.path}/view-torrey/:id`}>
            <AdminTorreyView />
          </Route>
          <Route path={`${match.path}/edit-torrey/:id`}>
            <AdminTorreyCreate />
          </Route>
          <Route path={`${match.path}/user-management`}>
            <UserManagement />
          </Route>
          <Route path={`${match.path}/maps`}>
            <AdminMapList />
          </Route>
          <Route path={`${match.path}/create-maps`}>
            <AdminMapCreate />
          </Route>
          <Route path={`${match.path}/view-map/:id`}>
            <AdminMapView />
          </Route>
          <Route path={`${match.path}/edit-map/:id`}>
            <AdminMapCreate />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
