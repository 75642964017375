import React from 'react';
import UserTorreyList from './UserTorrey/UserTorreyList';
// import UserTorreyList from "../..";

function TorreyBox({ toggles, handleClose }) {
  const closeTorreyBox = () => {
    handleClose('torrey');
  };
  return (toggles.torrey ? (
    <div className={`box torrey-box aside`}>
      <div className="box-header">
        <h2>Torrey's Dictionary</h2>
        <span className="close-buttonn" onClick={closeTorreyBox}>
          &times;
        </span>
      </div>
      <div className="user-article">
        <UserTorreyList />
      </div>
    </div>
  ) : null);
}

export default TorreyBox;
