import axios from 'axios';
import { API_URL } from "./app-config";
import { API_URL_QA } from "./app-config";

let apiUrl = API_URL
if (apiUrl == "auto") {
    apiUrl = window.location.origin + '/api';
    if (window.location.hostname == "localhost") { // for devs direct to qa server
        apiUrl = API_URL_QA;
    }
}

const api = axios.create({
    baseURL: apiUrl,
    headers: {
        'Authorization': "Bearer "+ localStorage.getItem("access_token")
    },
});

export default api;