import { useEffect, useState, useRef } from "react";
import { Route, Switch } from "react-router";
import About from "./pages/about/about";
import UserSongPage from "./pages/SongPage";
import LoginPage from "./pages/auth/LoginPage";
import AppBar from "./components/AppBar/AppBar";
import BiblePage from "./pages/BiblePage/BiblePage";
import RegisterPage from "./pages/auth/RegisterPage";
import SermonPage from "./pages/SermonPage/SermonPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import CalendarPage from "./pages/CalendarPage/CalendarPage";
import PrayerListPage from "./pages/PrayerListPage/PrayerListPage";
import AdminDashboardPage from "./pages/admin/AdminDashboardPage/AdminDashboardPage";
import "./App.css";
import { getUser } from "./services/authService";
import { getChapter } from "./services/bibleService";
import { getChapterNotesAndHighlights } from "./services/noteAndHighlightService";
import ForgotPassword from "./pages/auth/forgotPassword";
import Maps from "./pages/map/maps";
import CreateEvent from "./pages/CalendarPage/createEvent";
import PrayerGroupList from "./pages/PrayerListPage/PrayerGroupList";
import SettingsPage from "./pages/Settings/SettingsPage";
import FontSizePage from './pages/Settings/FontSize/FontSizePage'
import {LST_SLCTD_VERSE_LENGTH} from './utils/bibleconstants'
function App() {
  const [userIsLoading, setUserIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [bookNo, setBookNo] = useState(localStorage.getItem("book_no") ?? 1);
  const [oldBookNo, setOldBookNo] = useState(bookNo);
  const [chapterNo, setChapterNo] = useState(
    localStorage.getItem("chapter_no") ?? 1
  );
  const [oldChapterNo, setOldChapterNo] = useState(chapterNo);
  const [verseNo, setVerseNo] = useState(localStorage.getItem("verse_no") ?? 1);
  const [verseCount, setVerseCount] = useState(0);
  const [globalVersions, setGlobalVersions] = useState(
    localStorage.getItem("versions")
      ? localStorage.getItem("versions").split(",")
      : ["english_nkjv"]
  );
  const [primaryVersion, setPrimaryVersion] = useState(
    localStorage.getItem("primary_version") ?? "english_nkjv"
  );
  const [bibleData, setBibleData] = useState({});
  const [chapterNotes, setChapterNotes] = useState([]);
  const [noteVerseNo, setNoteVerseNo] = useState("0");
  const [chapterHighlights, setChapterHighlights] = useState([]);
  const [currentPage, setCurrentPage] = useState("");
  const [verseScroll, setVerseScroll] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
 
  // Authenticating user
  useEffect(() => {
    async function fetchUserData() {
      try {
        if (localStorage.getItem("access_token")) {
          await getUser().then(response => {
            setUserIsLoading(false);
            setUser(response.data);
          });
          await getChapterNotesAndHighlights(bookNo, chapterNo).then(
            notesAndHighlights => {
              setChapterNotes(notesAndHighlights.data.notes);
              setNoteVerseNo("0");
              setChapterHighlights(notesAndHighlights.data.highlights);
            }
          );
        } else {
          setUserIsLoading(false);
        }
      } catch (error) {
        setUserIsLoading(false);
        if (error.response?.status === 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("token_type");
        }
      }
    }
    fetchUserData();
  }, [bookNo, chapterNo]);

  async function fetchBibleData(bookNo, chapterNo, versions, verseNo) {
    try {
      if (!navigator.onLine) {
        setErrorMessage(
          "Unable to load the chapter.Please check your internet connection"
        );
        return;
      }
      await getChapter(bookNo, chapterNo, versions).then(response => {
        setBibleData(response.data);
        setVerseCount(Object.keys(response.data[versions[0]]).length);
        changeVerse(verseNo, "search");
      });
    } catch (error) {
      console.log(error);
      setErrorMessage("An unknown error occurred.Please try again later");
    }
  }
  function getStoredBibleData() {
    const bookNo = localStorage.getItem("book_no") ?? 1;
    const chapterNo = localStorage.getItem("chapter_no") ?? 1;
    const verseNo = localStorage.getItem("verse_no") ?? 1;
    const versions = localStorage.getItem("versions")
      ? localStorage.getItem("versions").split(",")
      : ["english_nkjv"];
    return { bookNo, chapterNo, verseNo, versions };
  }

  useEffect(() => {
    const { bookNo, chapterNo, verseNo, versions } = getStoredBibleData();
    fetchBibleData(bookNo, chapterNo, versions, verseNo);
  }, [verseScroll]);

  useEffect(() => {
    const { bookNo, chapterNo, verseNo, versions } = getStoredBibleData();
    function handleOnline() {
      fetchBibleData(bookNo, chapterNo, versions, verseNo);
      setErrorMessage(null);
    }
    window.addEventListener("online", handleOnline);
    return function () {
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  const changeBook = no => {
    localStorage.setItem("book_no", no);
    localStorage.setItem("chapter_no", 1);
    setBookNo(no);
    setOldBookNo(no);
  };

  const changeChapter = async no => {
    // If "no" is set to 0, the chapter index was just reset to the start.
    // So we won't call the API.
    if (no === "0") {
      return "foo";
    }

    localStorage.setItem("chapter_no", no);

    const newBibles = {};

    try {
      await Promise.all(
        globalVersions.map(async single => {
          await getChapter(bookNo, no, [single]).then(response => {
            newBibles[single] = response.data[single];
          });
        })
      );

      setBibleData(newBibles);
      setChapterNo(no);
      setOldChapterNo(no);
      setBookNo(bookNo);
      setVerseCount(Object.keys(newBibles[globalVersions[0]]).length);
       } catch (error) {}
  };

  const changeBookAndChapter = (book, chapter) => {
    if (bookNo === book && chapterNo === chapter) {
      return new Promise((resolve, reject) => {
        resolve("foo");
      });
    }
    return getChapter(book, chapter, globalVersions).then(response => {
      setBibleData(response.data);
      setChapterNo(chapter);
      setOldChapterNo(chapter);
      setBookNo(book);
      setOldBookNo(book);
      setVerseCount(Object.keys(response.data[globalVersions[0]]).length);
      if (user) {
        getChapterNotesAndHighlights(book, chapter).then(response => {
          setChapterNotes(response.data.notes);
          setNoteVerseNo("0");
          setChapterHighlights(response.data.highlights);
        });
      }
    });
  };

  const changeVerse = (no, status) => {
    if (status !== "search") {
      localStorage.setItem("verse_no", no);
      setVerseScroll(no);
    }
    setVerseNo(no);
    const element = document.querySelector(
      ".bible-page .bible-box #bible-verse-" + no
    );
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      element.classList.add("blinking-verse");
      setTimeout(() => {
        element.classList.remove("blinking-verse");
      }, 3000);
    }
  };

  const addVersion = single => {
    if (!globalVersions.includes(single)) {
      const newVersions = [...globalVersions];

      if (bibleData[single]) {
        newVersions.push(single);
        localStorage.setItem("versions", newVersions.join());
        setGlobalVersions(newVersions);
        return;
      }

      getChapter(bookNo, chapterNo, [single]).then(response => {
        const newBibleData = { ...bibleData };
        newBibleData[single] = response.data[single];

        setBibleData(newBibleData);

        newVersions.push(single);
        localStorage.setItem("versions", newVersions.join());
        setGlobalVersions(newVersions);
      });
    }
  };

  const removeVersion = single => {
    if (globalVersions.includes(single)) {
      const newVersions = globalVersions.filter(item => item !== single);
      localStorage.setItem("versions", newVersions.join());
      setGlobalVersions(newVersions);
    }
  };

  const changeVersions = updated => {
    localStorage.setItem("versions", updated.join());

    let promises = [];
    let newBibles = { ...bibleData };

    updated.forEach(single => {
      if (!bibleData[single]) {
        promises.push(
          getChapter(bookNo, chapterNo, [single]).then(response => {
            newBibles[single] = response.data[single];
          })
        );
      }
    });

    Promise.allSettled(promises).then(() => {
      setBibleData(newBibles);
      setGlobalVersions(updated);
    });
  };

  const [lastSelectedVerses, setLastSelectedVerses] = useState(
    JSON.parse(localStorage.getItem("lastSelectedVerses")) || []
  );
 

  const lastSlctedVersesInLocalStorage = (book, chapter, verse) => {
    let array = [...lastSelectedVerses];
    if (array.length < LST_SLCTD_VERSE_LENGTH) {
      array.push({
        book: book,
        chapter: chapter,
        verse: verse,
      });
    } else {
      array.shift();
      array.push({
        book: book,
        chapter: chapter,
        verse: verse,
      });
    }
    setLastSelectedVerses(array);
    localStorage.setItem("lastSelectedVerses", JSON.stringify(array));
  };

  useEffect(() => {
    localStorage.setItem("lastSelectedVerses", JSON.stringify(lastSelectedVerses));
  }, [lastSelectedVerses]);

  return (
    <div className="App">
      <AppBar
        user={user}
        setUser={setUser}
        userIsLoading={userIsLoading}
        setUserIsLoading={setUserIsLoading}
        currentPage={currentPage}
      />
      <Switch>
        <Route path="/login">
          <LoginPage
            setUserIsLoading={setUserIsLoading}
            setUser={setUser}
            changeBookAndChapter={changeBookAndChapter}
            setCurrentPage={setCurrentPage}
          />
        </Route>
        <Route path="/register">
          <RegisterPage
            setUserIsLoading={setUserIsLoading}
            setUser={setUser}
            setCurrentPage={setCurrentPage}
          />
        </Route>
        <Route path="/forgotPassword" style={{ display: "hidden" }}>
          <ForgotPassword />
        </Route>
        <Route path="/prayer-list/:id/name/:groupName">
          <PrayerListPage
            userIsLoading={userIsLoading}
            user={user}
            setCurrentPage={setCurrentPage}
          />
        </Route>
        <Route path="/prayer-group-list">
          <PrayerGroupList
            userIsLoading={userIsLoading}
            user={user}
            setCurrentPage={setCurrentPage}
          />
        </Route>
        <Route path="/contact">
          <ContactPage
            userIsLoading={userIsLoading}
            user={user}
            setCurrentPage={setCurrentPage}
          />
        </Route>
        <Route path="/about">
          <About
            userIsLoading={userIsLoading}
            user={user}
            setCurrentPage={setCurrentPage}
          />
        </Route>
        <Route path="/calendar">
          <CalendarPage
            /* <Calendar */
            userIsLoading={userIsLoading}
            user={user}
            setCurrentPage={setCurrentPage}
          />
        </Route>
        <Route path="/create-event">
          <CreateEvent
            userIsLoading={userIsLoading}
            user={user}
            setCurrentPage={setCurrentPage}
          />
        </Route>
        <Route path="/edit-event/:event">
          <CreateEvent
            userIsLoading={userIsLoading}
            user={user}
            setCurrentPage={setCurrentPage}
          />
        </Route>
        <Route path="/sermon">
          <SermonPage
            userIsLoading={userIsLoading}
            user={user}
            setCurrentPage={setCurrentPage}
          />
        </Route>
        <Route path="/map">
          <Maps
            userIsLoading={userIsLoading}
            user={user}
            setCurrentPage={setCurrentPage}
          />
        </Route>
        <Route path="/songs">
          <UserSongPage
            userIsLoading={userIsLoading}
            user={user}
            setCurrentPage={setCurrentPage}
          />
        </Route>
        <Route exact path="/settings">
          <SettingsPage
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Route>
        <Route path="/settings/:childRoute">
          <SettingsPage
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          >
            <FontSizePage
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </SettingsPage>
        </Route>
        <Route path="/admin">
          <AdminDashboardPage setCurrentPage={setCurrentPage} />
        </Route>
        <Route path="/">
          <BiblePage
            userIsLoading={userIsLoading}
            user={user}
            errorMessage={errorMessage}
            bookNo={bookNo}
            oldBookNo={oldBookNo}
            changeBook={changeBook}
            chapterNo={chapterNo}
            oldChapterNo={oldChapterNo}
            changeChapter={changeChapter}
            changeBookAndChapter={changeBookAndChapter}
            verseNo={verseNo}
            changeVerse={changeVerse}
            verseCount={verseCount}
            setVerseCount={setVerseCount}
            globalVersions={globalVersions}
            setGlobalVersions={setGlobalVersions}
            addVersion={addVersion}
            removeVersion={removeVersion}
            changeVersions={changeVersions}
            primaryVersion={primaryVersion}
            setPrimaryVersion={setPrimaryVersion}
            bibleData={bibleData}
            chapterNotes={chapterNotes}
            setChapterNotes={setChapterNotes}
            noteVerseNo={noteVerseNo}
            setNoteVerseNo={setNoteVerseNo}
            chapterHighlights={chapterHighlights}
            setChapterHighlights={setChapterHighlights}
            setCurrentPage={setCurrentPage}
            // previousBookNo={previousBookNo}
            // previousChapterNo={previousChapterNo}
            // previousVerseNo={previousVerseNo}
            lastSlctedVersesInLocalStorage={lastSlctedVersesInLocalStorage}
            lastSelectedVerses={lastSelectedVerses}
            setLastSelectedVerses={setLastSelectedVerses}
          />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
