import { Link, useHistory,useLocation } from "react-router-dom";
import logo from "../../assets/images/lighthouse.png";
import { logout } from "../../services/authService";
import { getUser, login } from "../../services/authService";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";
import KeyboardBackspaceSharpIcon from "@mui/icons-material/KeyboardBackspaceSharp";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import {} from "@mui/material";

export default function AppBar({
  user,
  setUser,
  userIsLoading,
  userType,
  setUserIsLoading,
  currentPage,
}) {
  const history = useHistory();
  // const classes = useStyles();

  const [usrTypeget, setusrTypeget] = useState();
  const [mobileNavBarOpen, setMobileNavBarOpen] = useState(false);

  useEffect(() => {
    getUser()
      .then((response) => {
        // User data recived since access tken was valid.
        setUserIsLoading(false);
        setusrTypeget(response.data);
        let datas = response.data;
        setusrTypeget(datas.user_type);
      })
      .catch((error) => {});
  }, []);
  // console.log(usrTypeget);

  
  const openMobileNavBar = () => {
    setMobileNavBarOpen(true);
  };

  const closeMobileNavBar = () => {
    setMobileNavBarOpen(false);
  };

  const handleLogoutClick = () => {
    logout().then((response) => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("token_type");
      setUser(null);
      window.location.reload();
    });
  };

  return (
    <div className="app-bar">
      <div className="primary">
        <Link className="logo-link" to="/">
          <img src={logo} alt="Logo" />
          <span>Lighthouse</span>
        </Link>
       
        <button className="mobile-only" onClick={openMobileNavBar}>
          <MenuIcon style={{ color: "#fff" }} />
        </button>
      </div>
      <div className="app-bar-secondary mobile-hidden">
        <div className="nav-links">
          <Link
            className={currentPage === "" ? "nav-link active" : "nav-link"}
            to="/"
          >
            Home
          </Link>
          <Link
            className={currentPage === "songs" ? "nav-link active" : "nav-link"}
            to="/songs"
          >
            Songs
          </Link>
          <Link
            className={currentPage === "map" ? "nav-link active" : "nav-link"}
            to="/map"
          >
            Maps
          </Link>
          <Link
            className={
              currentPage === "calendar" ? "nav-link active" : "nav-link"
            }
            to="/calendar"
          >
            Calendar
          </Link>
          <Link
            className={
              currentPage === "sermon" ? "nav-link active" : "nav-link"
            }
            to="/sermon"
          >
            Sermons
          </Link>
          <Link
            className={
              currentPage === "settings" ? "nav-link active" : "nav-link"
            }
            to="/settings"
          >
            Settings
          </Link>
          <Link
            className={
              currentPage === "prayerGroupList" ? "nav-link active" : "nav-link"
            }
            to="/prayer-group-list"
          >
            Pray for
          </Link>
          <Link
            className={currentPage === "about" ? "nav-link active" : "nav-link"}
            to="/about"
          >
            About
          </Link>
          <Link
            className={
              currentPage === "contact" ? "nav-link active" : "nav-link"
            }
            to="/contact"
          >
            Contact
          </Link>

          {/* <Link className={currentPage === 'donate' ? "nav-link active" : "nav-link"} to="/donate">Donate</Link> */}
        </div>
        {!userIsLoading && user && (
          <div className="admin-panel">
            {usrTypeget == "1" ||
            user.email === "sathyakatta910@gmail.com" ||
            user.email === "emailjoelsam@gmail.com" ||
            user.email === "rkg.iist@gmail.com" ? (
              <Link
                className={
                  currentPage === "articles"
                    ? "admin-link active"
                    : "admin-link"
                }
                to="/admin/articles"
              >
                Admin Panel
              </Link>
            ) : null}
          </div>
        )}
        {!userIsLoading && !user && (
          <div className="auth-links">
            <Link to="/login" className="auth-link login-link">
              Login
            </Link>
            <Link to="/register" className="auth-link register-link">
              Register
            </Link>
          </div>
        )}

        {!userIsLoading && user && (
          <div className="user-section">
            <button className="user-button">{user.name}</button>
            <span className="user-email">{user.email}</span>
            <div className="user-links">
              <button className="user-link-button" onClick={handleLogoutClick}>
                Logout
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="appbar-mobile-div">
        <Drawer
          anchor={"right"}
          open={mobileNavBarOpen}
          onClose={closeMobileNavBar}
        >
          <Box
            role="presentation"
            onClick={closeMobileNavBar}
            onKeyDown={closeMobileNavBar}
          >
            <List>
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    history.push(`/calendar`);
                  }}
                  sx={{ py: 0, textAlign: "center" }}
                >
                  <ListItemText primary={"CALENDER"} />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    history.push(`/prayer-group-list`);
                  }}
                  sx={{ py: 0, textAlign: "center" }}
                >
                  <ListItemText primary={"PRAY FOR"} />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    history.push(`/sermon`);
                  }}
                  sx={{ py: 0, textAlign: "center" }}
                >
                  <ListItemText primary={"SERMONS"} />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    history.push(`/map`);
                  }}
                  sx={{ py: 0, textAlign: "center" }}
                >
                  <ListItemText primary={"MAPS"} />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    history.push(`/songs`);
                  }}
                  sx={{ py: 0, textAlign: "center" }}
                >
                  <ListItemText primary={"SONGS"} />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    history.push(`/settings`);
                  }}
                  sx={{ py: 0, textAlign: "center" }}
                >
                  <ListItemText primary={"SETTINGS"} />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    history.push(`/contact`);
                  }}
                  sx={{ py: 0, textAlign: "center" }}
                >
                  <ListItemText primary={"CONTACT"} />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    history.push(`/about`);
                  }}
                  sx={{ py: 0, textAlign: "center" }}
                >
                  <ListItemText primary={"ABOUT"} />
                </ListItemButton>
              </ListItem>
            </List>
            {!userIsLoading && !user && (
              <List>
                <ListItem>
                  <ListItemButton
                    onClick={() => {
                      history.push(`/login`);
                    }}
                    sx={{
                      py: 0,
                      textAlign: "center",
                      border: "1px solid #fff",
                      borderRadius: 2,
                    }}
                  >
                    <ListItemText primary={"LOGIN"} />
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    onClick={() => {
                      history.push(`/register`);
                    }}
                    sx={{
                      py: 0,
                      textAlign: "center",
                      background: "#fff",
                      color: "#3a65ff",
                      borderRadius: 2,
                    }}
                  >
                    <ListItemText primary={"REGISTER"} />
                  </ListItemButton>
                </ListItem>
              </List>
            )}
            {!userIsLoading && user && (
              <List>
                {usrTypeget == "1" ? (
                  <ListItem>
                    <ListItemButton sx={{ py: 0, textAlign: "center" }}>
                      <ListItemText
                        primary={"ADMIN PANEL"}
                        onClick={() => {
                          history.push(`/admin/articles`);
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ) : null}
                <ListItem>
                  <ListItemButton
                    sx={{
                      py: 0,
                      textAlign: "center",
                      border: "1px solid white",
                      borderRadius: 2,
                    }}
                  >
                    <ListItemText
                      primary={user.name}
                      secondary={user.email}
                      secondaryTypographyProps={{ color: "#ffffff" }}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    onClick={handleLogoutClick}
                    sx={{ py: 0, textAlign: "center" }}
                  >
                    <ListItemText primary={"LOGOUT"} />
                  </ListItemButton>
                </ListItem>
              </List>
            )}
          </Box>
        </Drawer>
      </div>
    </div>
  );
}
