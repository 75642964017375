import { useEffect, useState } from "react";
import { editNote } from "../../services/noteService";
import CommonEditor from "../CommonEditor/CommonEditor";

export default function NoteEditBox({
  currentEditNote,
  chapterNotes,
  setChapterNotes,
  setNoteVerseNo,
  toggles,
  handleToggle,
  handleClose,
}) {
  const [noteError, setNoteError] = useState("");
  const [noteText, setNoteText] = useState("");

  const closeBox = () => {
    handleClose("noteEditBox");
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();

    if (noteText === "") {
      setNoteError("Note cannot be empty");
      return;
    }

    editNote(currentEditNote.id, noteText).then((response) => {
      const newChapterNotes = { ...chapterNotes };
      let newVerseNotes = [];
      newChapterNotes[currentEditNote.verse_no].forEach((item) => {
        if (item.id === currentEditNote.id) {
          item.note = noteText;
          newVerseNotes.push(item);
        } else {
          newVerseNotes.push(item);
        }
      });
      newChapterNotes[currentEditNote.verse_no] = newVerseNotes;
      setChapterNotes(newChapterNotes);
      setNoteVerseNo(currentEditNote.verse_no);
      closeBox();
      handleToggle("notes");
    });
  };

  useEffect(() => {
    if (currentEditNote) {
      setNoteText(currentEditNote.note);
    }
  }, [currentEditNote]);

  return toggles.noteEditBox ? (
    <div className={`box note-edit-box aside`}>
      <div className="box-header">
        <h2>Edit Note</h2>
        <span className="close-buttonn" onClick={closeBox}>
          &times;
        </span>
      </div>
      <div className="box-content">
        <form className="note-edit-form" onSubmit={handleEditFormSubmit}>
          <CommonEditor value={noteText} onChangeText={setNoteText} />
          <div className="note-error">{noteError}</div>
          <input type="submit" value="Update Note" className="button" />
        </form>
      </div>
    </div>
  ) : null;
}
