import { useEffect, useState } from "react";
import SermonCreate from "../../components/SermonCreate/SermonCreate";
import SermonEdit from "../../components/SermonEdit/SermonEdit";
import SermonIndex from "../../components/SermonIndex/SermonIndex";
import SermonView from "../../components/SermonView/SermonView";
import { getSermons } from "../../services/sermonService";
import BackButton from "../BackButton/BackButton";

export default function SermonPage({ userIsLoading, user, setCurrentPage }) {
  const [activeTab, setActiveTab] = useState("index");
  const [sermons, setSermons] = useState([]);
  const [viewingSermon, setViewingSermon] = useState(null);
  const [editingSermon, setEditingSermon] = useState(null);

  useEffect(() => {
    setCurrentPage("sermon");
  }, []);

  useEffect(() => {
    getSermons().then((response) => {
      setSermons(response.data);
    });
  }, []);

  if (!user) {
    return (
      <div className="dialog">
        <p className="dialog-box">
          You need to be logged in to use this feature.
        </p>
      </div>
    );
  }

  return (
    <>
      <BackButton/>
      <div className="sermon-page">
        {activeTab === "index" && (
          <SermonIndex
            setActiveTab={setActiveTab}
            setViewingSermon={setViewingSermon}
            sermons={sermons}
          />
        )}
        {activeTab === "create" && (
          <SermonCreate
            setActiveTab={setActiveTab}
            sermons={sermons}
            setSermons={setSermons}
            user={user}
          />
        )}
        {viewingSermon && activeTab === "view" && (
          <SermonView
            user={user}
            sermon={viewingSermon}
            sermons={sermons}
            setSermons={setSermons}
            setEditingSermon={setEditingSermon}
            setActiveTab={setActiveTab}
          />
        )}
        {editingSermon && activeTab === "edit" && (
          <SermonEdit
            sermon={editingSermon}
            sermons={sermons}
            setSermons={setSermons}
            setViewingSermon={setViewingSermon}
            setActiveTab={setActiveTab}
          />
        )}
      </div>
    </>
  );
}
