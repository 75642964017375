import React, { useEffect, useState } from 'react'
import { createPrayerGroup, getPrayerGroupsAndList } from '../../services/prayerService';
import Loading from '../../components/loading/Loading';
import { Button, Dialog, DialogContent, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import BackButton from '../BackButton/BackButton';

function PrayerGroupList({
    userIsLoading,
    user,
    setCurrentPage,
}) {
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [prayerGroups, setPrayerGroups] = useState([]);
    const [open, setOpen] = useState(false);
    const [formGroupName, setFormGroupName] = useState("");
    const [formGroupError, setFormGroupError] = useState("");

    const getGroupList = () => {
        setLoading(true);
        getPrayerGroupsAndList().then((response) => {
            setPrayerGroups(response.data.groups);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        });
    }

    useEffect(() => {
        getGroupList();
    }, []);

    useEffect(() => {
        setCurrentPage("prayerGroupList");
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCreateClick = () => {
        if (formGroupName === "") {
            setFormGroupError("Name cannot be empty");
            return;
        }

        createPrayerGroup(formGroupName).then((response) => {
            let newPrayerGroups = [...prayerGroups];
            newPrayerGroups.push(response.data);
            setPrayerGroups(newPrayerGroups);
            setFormGroupError("");
            setFormGroupName("");
            handleClose();
        });
    };

    const groupNameClick = (id) => {
        history.push(`prayer-list/${id}/name/${JSON.stringify(prayerGroups)}`);
    }

    return (
      <>
        <div className="prayer-list-page main-bg">
          <BackButton />
          <div className="container">
            <div className="prayer-group-head">
              <h1>Group List</h1>
              <div className="prayer-group-head-child">
                <Button
                  variant="contained"
                  style={{
                    background: "#3a65ff",
                    color: "#fff",
                    margin: "10px 5px",
                  }}
                  onClick={handleClickOpen}
                >
                  Create Prayer Group
                </Button>
              </div>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div className="prayer-group-card">
                <table>
                  <tbody>
                    {prayerGroups.length > 0 ? (
                      prayerGroups?.map((group, index) => (
                        <tr key={group.id}>
                          <td className="player-group-table-index">
                            {index + 1}
                          </td>
                          <td
                            onClick={() => {
                              groupNameClick(group.id, group.name);
                            }}
                          >
                            {group.name}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <span className="no-songs">No groups found</span>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            id="form-dialog"
          >
            <div className="close">
              <h3>Create Prayer Group</h3>
              <span className="close-button" onClick={handleClose}>
                &times;
              </span>
            </div>
            <DialogContent>
              {!user && <div>You need to be loggged in to create group.</div>}
              {user && (
                <div className="form_item">
                  <label>
                    Name of the Group
                    <span className="required" style={{ color: "#FF0000" }}>
                      *
                    </span>
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Name of the Group"
                    type="text"
                    name="group-name"
                    value={formGroupName}
                    onChange={event => {
                      setFormGroupName(event.target.value);
                    }}
                    style={{ marginBottom: 15 }}
                  />
                  <div>
                    <span style={{ color: "red" }}>{formGroupError}</span>
                  </div>
                </div>
              )}
            </DialogContent>
            <div className="dialog-msg">
              {user && (
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#3a65ff", color: "#fff" }}
                  onClick={handleCreateClick}
                >
                  Create
                </Button>
              )}
            </div>
          </Dialog>
        </div>
      </>
    );
}

export default PrayerGroupList;