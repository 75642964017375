import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { sendContactMessage } from "../../services/contactService";
import { TextField, Button } from "@material-ui/core";
import BackButton from "../BackButton/BackButton";

// import "./ContactPage.css";

export default function ContactPage({ userIsLoading, user, setCurrentPage }) {
  const history = useHistory();

  let formDataInitialValue = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };
  const [contact, setContact] = useState(formDataInitialValue);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState({
    name: false,
    email: false,
    subject: false,
    message: false,
  });

  const regex = /\S+@\S+\.\S+/;
  const checkError = () => {
    setError({
      name: contact.name === "" ? "Name cannot be empty" : false,
      email:
        contact.email === ""
          ? "Email cannot be empty"
          : !regex.test(contact.email)
          ? "Please enter a valid email"
          : false,
      subject: contact.subject === "" ? "Subject cannot be empty" : false,
      message: contact.message === "" ? "Message cannot be empty" : false,
    });
  };

  const BacktoHome = () => {
    history.push({
      pathname: `/home`,
    });
  };
  useEffect(() => {
    setCurrentPage("contact");
  }, []);

  const handleSendClick = () => {
    let data = {
      name: contact.name,
      email: contact.email,
      subject: contact.subject,
      message: contact.message,
    };
    if (
      data.name === "" ||
      data.email === "" ||
      data.subject === "" ||
      data.message === ""
    ) {
      checkError();
    } else {
      sendContactMessage(data).then((response) => {
        setSuccessMessage(
          "Your message has been sent. We will get in touch with you soon."
        );
        BacktoHome();
      });
    }
  };

  return (
    <>
      <BackButton />
      <div className="contact">
        <div className="contact-page">
          <h1>Contact us</h1>
          <div className="contact-form">
            {successMessage !== "" && (
              <span className="message">{successMessage}</span>
            )}
            <div className="input">
              <label>
                Name
                <span className="required" style={{ color: "#FF0000" }}>
                  *
                </span>
              </label>

              <br />
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                type="text"
                placeholder="Name"
                onChange={e =>
                  setContact({
                    ...contact,
                    name: e.target.value,
                  })
                }
                value={contact.name}
              />
              {error.name ? (
                <div style={{ color: "red", textAlign: "left" }}>
                  {" "}
                  {error.name}{" "}
                </div>
              ) : null}
            </div>

            <div className="input">
              <label>
                Email
                <span className="required" style={{ color: "#FF0000" }}>
                  *
                </span>
              </label>

              <br />
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                type="email"
                placeholder="Email"
                onChange={e =>
                  setContact({
                    ...contact,
                    email: e.target.value,
                  })
                }
                value={contact.email}
              />
              {error.email ? (
                <div style={{ color: "red", textAlign: "left" }}>
                  {" "}
                  {error.email}{" "}
                </div>
              ) : null}
            </div>

            <div className="input">
              <label>
                Subject
                <span className="required" style={{ color: "#FF0000" }}>
                  *
                </span>
              </label>

              <br />
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                type="text"
                placeholder="Subject"
                onChange={e =>
                  setContact({
                    ...contact,
                    subject: e.target.value,
                  })
                }
                value={contact.subject}
              />
              {error.subject ? (
                <div style={{ color: "red", textAlign: "left" }}>
                  {" "}
                  {error.subject}{" "}
                </div>
              ) : null}
            </div>

            <div className="input">
              <label>
                Message / Feedback
                <span className="required" style={{ color: "#FF0000" }}>
                  *
                </span>
              </label>

              <br />
              <TextField
                id="outlined-multiline-static"
                margin="dense"
                fullWidth
                multiline
                minRows={4}
                type="text"
                variant="outlined"
                onChange={e =>
                  setContact({
                    ...contact,
                    message: e.target.value,
                  })
                }
                value={contact.message}
              />
              {error.message ? (
                <div style={{ color: "red", textAlign: "left" }}>
                  {" "}
                  {error.message}{" "}
                </div>
              ) : null}
            </div>
            <div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ backgroundColor: "#3a65ff", color: "#fff" }}
                onClick={handleSendClick}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
