import { useEffect, useState } from "react";
import { bookNames } from "../../bibleMeta";
import { Stack } from "@mui/material";
import './VerseViewModal.css'
import AppBar from "../AppBar/AppBar";
export default function VerseView({
  oldBookNo,
  oldChapterNo,
  globalVersions,
  bibleData,
  verseCount,
  setVerseViewOpen,
  user,
}) {
  const [currentVerse, setCurrentVerse] = useState(1);
  const [versePerSlide, setVersePerSlide] = useState(1);

  const renderVersions = verseNo => {
    const elements = [];
    globalVersions.forEach((version, index) => {
      elements.push(
        <div key={index} className="version">
          <h2 className="version-index">
            {bookNames[version]
              ? bookNames[version][Number(oldBookNo - 1)]
              : bookNames["english_nkjv"][Number(oldBookNo - 1)]}
            {oldChapterNo}: {verseNo}
          </h2>
          <div className="version-verse">{bibleData[version][verseNo]}</div>
        </div>
      );
    });
    return elements;
  };

  const renderVerses = () => {
    const elements = [];

    for (
      let i = Number(currentVerse);
      i < Number(currentVerse) + Number(versePerSlide);
      i++
    ) {
      if (i <= verseCount) {
        elements.push(
          <Stack direction="row" spacing={1}>
            {renderVersions(i)}
          </Stack>
        );
      }
    }

    return elements;
  };

  const renderVerseOptions = () => {
    const elements = [];
    for (let i = 1; i <= verseCount; i++) {
      elements.push(
        <option key={i} value={i}>
          Verse {i}
        </option>
      );
    }
    return elements;
  };

  const nextVerse = () => {
    if (currentVerse < Number(verseCount)) {
      setCurrentVerse(currentVerse + 1);
    }
  };

  const previousVerse = () => {
    if (currentVerse > 1) {
      setCurrentVerse(currentVerse - 1);
    }
  };

  const handleVerseChange = event => {
    setCurrentVerse(Number(event.target.value));
  };
  const [safariBrowser, setSafariBrowser] = useState(false);
  const projection = () => {
    const element = document.querySelector(".verse-view-modal");
    var isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf("Apple") > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf("CriOS") == -1 &&
      navigator.userAgent.indexOf("FxiOS") == -1;

    if (isSafari) {
      setSafariBrowser(true);
      element.webkitRequestFullscreen();
    } else {
      setSafariBrowser(false);
      element.requestFullscreen?.() ||
        element.msRequestFullscreen?.() ||
        element.mozRequestFullScreen?.();
    }
  };

  useEffect(() => {
    projection();
    const onFullScreenChange = () => {
      const isFullScreenActive =
        document.fullscreenElement || document.webkitFullscreenElement;
      if (!isFullScreenActive) {
        setVerseViewOpen(false);
      }
    };
    document.addEventListener("fullscreenchange", onFullScreenChange);
    document.addEventListener("webkitfullscreenchange", onFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", onFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        onFullScreenChange
      );
    };
  }, []);

  const closeVerseView = () => {
    if (document.fullscreenElement || document.webkitFullscreenElement) {
      document.exitFullscreen?.() ||
        document.webkitExitFullscreen?.() ||
        document.msExitFullscreen?.() ||
        document.mozCancelFullScreen?.();
    }
    setVerseViewOpen(false);
  };

  return (
    <>
      {/* {user.name&&user.email?} */}
      <div
        className="modal verse-view-modal"
        tabIndex="0"
        //  style={{ height: safariBrowser ? "100vh" : "auto" }}
      >
        <div className="verse-view-content" style={{ overflowY: "auto" }}>
          <div className="header">
            <div className="verse-view-controls">
              <button onClick={previousVerse} className="prev-button">
                &#60;
              </button>
              <select
                value={currentVerse}
                onChange={handleVerseChange}
                className={safariBrowser ? "safariBrowVerOpt" : "verseOptions"}
                style={{ overflowY: "scroll" }}
              >
                {renderVerseOptions()}
              </select>
              <button className="next-button" onClick={nextVerse}>
                &#62;
              </button>
              <select
                className={
                  safariBrowser ? "safariBrowslctverpersld" : "slctverpersld"
                }
                value={versePerSlide}
                onChange={event => {
                  setVersePerSlide(Number(event.target.value));
                }}
              >
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <span className="perslide-text"> Verse(s) per slide</span>
            </div>
            {safariBrowser ? (
              <span className="safariBrowClsBtn" onClick={closeVerseView}>
                &times;
              </span>
            ) : (
              <span className="close-button" onClick={closeVerseView}>
                &times;
              </span>
            )}
          </div>
          <div className="verse-view-verses">{renderVerses()}</div>
        </div>
      </div>
    </>
  );
}


