import React, { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import { getSongById } from "../../services/songService";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "@material-ui/core";
import { Stack } from "@mui/material";

function UserSongView(props) {
  let { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const BacktoSongList = () => {
    history.push({
      pathname: `/songs`,
      state: { page: location.state.page ?? 1 },
    });
  };
  const [lyric, setLyric] = useState([]);
  const changeLyric = e => {
    getSongById(id)
      .then(res => {
        setLoading(false);
        setLyric(res.data.data);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  useEffect(() => {
    changeLyric();
  }, []);

  const [loading, setLoading] = useState(true);
  const [openProjectView, setOpenProjectView] = useState(false);
  const [projectView, setProjectView] = useState({});
  const [safariBrowser, setSafariBrowser] = useState(false);
  const ProjectLyrics = item => {
    setOpenProjectView(true);
    lyric.contents[item].index = item;
    lyric.contents[item].length = lyric.contents.length;
    setProjectView(lyric.contents[item]);
    const element = document.querySelector(".lyrics-view");
    var isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf("Apple") > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf("CriOS") == -1 &&
      navigator.userAgent.indexOf("FxiOS") == -1;

    if (isSafari) {
      setSafariBrowser(true);
      element.webkitRequestFullscreen();
    } else {
      setSafariBrowser(false);
      element.requestFullscreen?.() ||
        element.msRequestFullscreen?.() ||
        element.mozRequestFullScreen?.();
    }
  };
  // for closing song projector
  const closeSongProjector = () => {
    if (document.fullscreenElement || document.webkitFullscreenElement) {
      document.exitFullscreen?.() ||
        document.webkitExitFullscreen?.() ||
        document.msExitFullscreen?.() ||
        document.mozCancelFullScreen?.();
    }
    setOpenProjectView(false);
  };

  const OnLyricsChange = (item, state) => {
    if (state === "next") {
      lyric.contents[item + 1].index = item + 1;
      lyric.contents[item + 1].length = lyric.contents.length;
      setProjectView(lyric.contents[item + 1]);
    } else if (state === "prev") {
      lyric.contents[item - 1].index = item - 1;
      lyric.contents[item - 1].length = lyric.contents.length;
      setProjectView(lyric.contents[item - 1]);
    }
  };

  document.addEventListener("keydown", function (event) {
    if (event.key === "37") {
      if (projectView.index > 0) {
        OnLyricsChange(projectView.index, "prev");
      }
    }
    if (event.key === "39") {
      if (projectView.length > projectView.index + 1) {
        OnLyricsChange(projectView.index, "next");
      }
    }
  });

  return (
    <div>
      <Button
        onClick={BacktoSongList}
        startIcon={<ArrowBackIcon className="icon" />}
      >
        Back
      </Button>
      {/* </button> */}
      <div className="song-view">
        <div className="songs">
          {loading ? (
            <Loading />
          ) : (
            <div className="song-sheet">
              <div className="song-title">
                {lyric ? <h3>{`${lyric.id}: ${lyric.title}`}</h3> : null}
              </div>
              <div className="song-lyric safari-song-lyric">
                <div className="song-lang-1">
                  {lyric.contents?.length > 0
                    ? lyric.contents.map((item, index) => (
                        <p
                          key={item.id}
                          onClick={() => ProjectLyrics(index)}
                          dangerouslySetInnerHTML={{
                            __html: item.content.replaceAll("\n", "<br/>"),
                          }}
                        />
                      ))
                    : null}
                </div>
                {lyric && lyric.language_id == 3 ? null : (
                  <div className="song-lang-2">
                    {lyric.contents?.length > 0
                      ? lyric.contents.map((item, index) => (
                          <p
                            key={item.id}
                            onClick={() => ProjectLyrics(index)}
                            dangerouslySetInnerHTML={{
                              __html: item.transliterature.replaceAll(
                                "\n",
                                "<br/>"
                              ),
                            }}
                          />
                        ))
                      : null}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Song Project View */}
        <div className="lyrics-view">
          {openProjectView ? (
            <div className="lyrics-content modal songProjector">
              <div className="verse-view-content song-content">
                {safariBrowser ? (
                  <span className="safariBrowser" onClick={closeSongProjector}>
                    &times;
                  </span>
                ) : (
                  <span className="close-buttone" onClick={closeSongProjector}>
                    &times;
                  </span>
                )}
                <div className="song-project">
                  <div className="song-view-control">
                    <div>
                      {projectView.index < 1 ? null : (
                        <IconButton
                          value="prev"
                          className="icon-button"
                          onClick={() =>
                            OnLyricsChange(projectView.index, "prev")
                          }
                        >
                          <KeyboardArrowLeftIcon />
                        </IconButton>
                      )}
                    </div>
                    <div className="verse-view-verses song-view">
                      <p
                        className="song-projection"
                        style={{ color: "#FFF" }}
                        dangerouslySetInnerHTML={{
                          __html: projectView.content.replaceAll("\n", "<br/>"),
                        }}
                      />
                      <p
                        className="song-projection"
                        style={{ color: "#FFF" }}
                        dangerouslySetInnerHTML={{
                          __html: projectView.transliterature.replaceAll(
                            "\n",
                            "<br/>"
                          ),
                        }}
                      />
                    </div>
                    <div>
                      {lyric.contents.length ===
                      projectView.index + 1 ? null : (
                        <IconButton
                          value="next"
                          className="icon-button"
                          onClick={() =>
                            OnLyricsChange(projectView.index, "next")
                          }
                        >
                          <KeyboardArrowRightIcon />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default UserSongView;
