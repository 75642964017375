
import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import "./settings.css";
import logo from "../../assets/images/lighthouse.png";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useMediaQuery } from "@mui/material";
import KeyboardBackspaceSharpIcon from "@mui/icons-material/KeyboardBackspaceSharp";
export default function SettingsPage({
  children,
  currentPage,
  setCurrentPage,
}) {
  const history = useHistory();
  const [expandOpen, setExpandOpen] = useState(false);
  const { childRoute } = useParams();

  useEffect(() => {
    if (childRoute === "font-size") {
      setCurrentPage("settings/font-size");
      setExpandOpen(true);
    } else {
      setCurrentPage("settings");
      setExpandOpen(false);
    }
  }, [childRoute, setCurrentPage]);

  const handleToggleExpand = () => {
    setExpandOpen(prev => !prev);
  };
  const handleButtonClick = () => {
    handleToggleExpand();
    history.push(expandOpen ? "/settings" : "/settings/font-size");
  };
  const ifMobile = useMediaQuery("((max-width: 600px)");
  return (
    <div className="settings-layout">
      {ifMobile ? (
        <div>
          <div className="mobile-settings-header">
            <KeyboardBackspaceSharpIcon
              className="mobile-arrow-back"
              onClick={() => history.push("/")}
            />
            <h1>Settings</h1>
          </div>
          <div className="mobile-home-setting-buttons ">
            <Link to="/" className={"mobile-navigation-buttons"}>
              Home
            </Link>
            <Link
              to="/settings"
              className={
                currentPage === "settings"
                  ? "mobile-active mobile-navigation-buttons"
                  : "mobile-navigation-buttons"
              }
            >
              Settings
            </Link>
            {children && (
              <Link
                to="/settings/font-size"
                className={
                  currentPage === "settings/font-size"
                    ? "mobile-active mobile-navigation-buttons"
                    : "mobile-navigation-buttons"
                }
              >
                FontSize
              </Link>
            )}
          </div>
          <div>
            <button
              className="mobile-settings-button"
              onClick={handleButtonClick}
            >
              <TextFieldsIcon className="mobile-button-icon" />
              Adjust FontSize
              {expandOpen ? (
                <span className="-mobile-expand">
                  <ExpandMoreIcon />
                </span>
              ) : (
                <span className="mobile-expand">
                  <ExpandLessIcon />
                </span>
              )}
            </button>
          </div>
          <div>
            <div className="mobile-children">{children}</div>
          </div>
        </div>
      ) : (
        <>
          <div className="settings-sidebar">
            <div className="settings-header">
              <KeyboardBackspaceSharpIcon
                className="arrow-back"
                onClick={() => history.push("/")}
              />
              <h1>Settings</h1>
            </div>

            <button className="settings-button" onClick={handleButtonClick}>
              <TextFieldsIcon className="button-icon" />
              Adjust FontSize
              {expandOpen ? (
                <span className="expand">
                  <ExpandMoreIcon />
                </span>
              ) : (
                <span className="expand">
                  <ExpandLessIcon />
                </span>
              )}
            </button>
          </div>

          <div className="settings-content">
            <div className="home-setting-font-buttons">
              <Link to="/" className={"navigation-buttons"}>
                <img src={logo} alt="Logo-Home" className="logo-home" /> Home
              </Link>
              <Link
                to="/settings"
                className={
                  currentPage === "settings"
                    ? "active navigation-buttons"
                    : "navigation-buttons"
                }
              >
                Settings
              </Link>
              {children && (
                <Link
                  to="/settings/font-size"
                  className={
                    currentPage === "settings/font-size"
                      ? "active navigation-buttons"
                      : "navigation-buttons"
                  }
                >
                  FontSize
                </Link>
              )}
            </div>

            {children && <h1 className="child-heading">Adjust FontSize</h1>}

            <div className="children">{children}</div>
          </div>
        </>
      )}
    </div>
  );

  }
