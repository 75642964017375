import React, { useState, useEffect } from "react";
import Slider from "@mui/material/Slider";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import './fontsize.css'

export default function FontSizePage({ currentPage, setCurrentPage }) {
  const [fontSize, setFontSize] = useState(16);
  useEffect(() => {
    setCurrentPage("font-size");
  }, []);
  const handleFontSize = (event, newSize) => {
    setFontSize(newSize);
    localStorage.setItem("fontSize", newSize);
  };

  const incrementFontSize = () => {
    if (fontSize < 25) {
      const newSize = fontSize + 1;
      setFontSize(newSize);
      localStorage.setItem("fontSize", newSize);
    }
  };

  const decrementFontSize = () => {
    if (fontSize > 15) {
      const newSize = fontSize - 1;
      setFontSize(newSize);
      localStorage.setItem("fontSize", newSize);
    }
  };

  useEffect(() => {
    const savedFontSize = localStorage.getItem("fontSize");
    if (savedFontSize) {
      setFontSize(Number(savedFontSize));
    }
  }, []);

  return (
    <div className="slider-container">
      <div className="slider-box">
        <h2>Adjust Font Size</h2>
        <p>Use the slider or buttons to adjust the font size of the text:</p>

        <div className="font-controls">
          <button onClick={decrementFontSize}>
            <RemoveIcon />
          </button>
          <span className="current-size">{fontSize}px</span>
          <button onClick={incrementFontSize}>
            <AddIcon />
          </button>
        </div>

        <Slider
          className="slider"
          value={fontSize}
          onChange={handleFontSize}
          min={15}
          max={25}
          step={1}
        />

        <p style={{ fontSize: `${fontSize}px`, marginTop: "20px" }}>
          For God so loved the world that He gave His only begotten Son, that
          whoever believes in Him should not perish but have everlasting life. -
          John 3:16
        </p>

        <p style={{ marginTop: "20px", color: "", fontStyle: "italic" }}>
          Selected Font Size: {fontSize}px
        </p>

        <p style={{ marginTop: "10px", color: "#FF0000" }}>
          *Move the slider left to decrease the font size and right to increase
          it.
        </p>
      </div>
    </div>
  );
}
