import { useHistory } from 'react-router-dom';
import KeyboardBackspaceSharpIcon from "@mui/icons-material/KeyboardBackspaceSharp";
import './backbutton.css';
export default function BackButton() {
    const history = useHistory();
    const navigateToHomePage = () => {
        history.push('/');
    }
    return (
      <>
        <div className="bckbtn">
          <button className="back-button" onClick={navigateToHomePage}>
            <KeyboardBackspaceSharpIcon /> <span>Back</span>
          </button>
        </div>
      </>
    );
}